$core-styles-in-project: true;

/******* PreCSS Variables */
@import '~@vfde-brix/ws10/styles/scss/precss/precss';

// hide the old flyout
.vf-flyout {
    display: none !important;
}

/******* Accessories */
@import '~@vfde-brix/ws10/styles/scss/accessories/card';
@import '~@vfde-brix/ws10/styles/scss/accessories/color-helpers';
@import '~@vfde-brix/ws10/styles/scss/accessories/headlines';
@import '~@vfde-brix/ws10/styles/scss/accessories/text';
@import '~@vfde-brix/ws10/styles/scss/accessories/spacing';
@import '~@vfde-brix/ws10/styles/scss/accessories/oocss';
@import '~@vfde-brix/ws10/styles/scss/accessories/links';
@import '~@vfde-brix/ws10/styles/scss/accessories/list';
@import '~@vfde-brix/ws10/styles/scss/accessories/table';
@import '~@vfde-brix/ws10/styles/scss/accessories/animation';
@import '~@vfde-brix/ws10/styles/scss/accessories/divider-line';

/******* Overrides */
@import '~@vfde-brix/ws10/styles/scss/accessories/states';

/******* Patterns */
@import '~@vfde-brix/ws10/headline/headline';
@import '~@vfde-brix/ws10/accordion/accordion';
@import '~@vfde-brix/ws10/button/button';
@import '~@vfde-brix/ws10/button-link/button-link';
@import '~@vfde-brix/ws10/button-icon-only/button-icon-only';
@import '~@vfde-brix/ws10/highlight-badge/highlight-badge';
@import '~@vfde-brix/ws10/link-tiles/link-tiles';
@import '~@vfde-brix/ws10/tooltip/tooltip';
@import '~@vfde-brix/ws10/icon/icon';
@import '~@vfde-brix/ws10/system-icon/system-icon';
@import '~@vfde-brix/ws10/form-selection-control/form-selection-control';
@import '~@vfde-brix/ws10/interaction-list/interaction-list';
@import '~@vfde-brix/ws10/list-card/list-card';
@import '~@vfde-brix/ws10/tabs/tabs';
@import '~@vfde-brix/ws10/overlay/overlay';
@import '~@vfde-brix/ws10/belt-container/belt-container';
@import '~@vfde-brix/ws10/flyout/flyout';

/* Flyout */
.contact-flyout {
    // TODO: this is the actual trigger button. Only this needs styling. Name is suboptimal. .contact-flyout is used
    //  as wrapper for this AND as wrapper for the actual flyout AND for scoping which is also suboptimal.
    //  We need a bit of enablement, so we have one scoping wrapper (.contact-flyout) and one separate class to style the actual flyout.
    &__drawer {
        position: fixed;
        bottom: 96px;
        right: 0;
        z-index: 100;

        /* stylelint-disable */
        #contact-flyout__trigger {
            width: 56px;
            overflow: hidden;
        }
    }
}

// The webchat panel needs 100% height
// TODO: maybe make this an option in CMS?
#flyout-webchat {
    height: 100%;
}

// TOBI container doesn't need any overlay padding.
#flyout-webchat-container {
    position: relative;
    top: calc(var(--overlay-padding-top) * -1);
    left: calc(var(--overlay-padding-left) * -1);
    right: calc(var(--overlay-padding-right) * -1);
    width: calc(100% + var(--overlay-padding-right) + var(--overlay-padding-left));
    height: calc(100% + var(--overlay-padding-top));
    margin-bottom: calc(var(--overlay-padding-top) * -1);
}
